const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  cityListData: [],
  leadReportData: [],
  sellPersonData: [],
  multiQuotationData:[]

}

const leadReport = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_LEAD_REPORT':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        statusCounts: action.statusCounts
      }
    case 'GET_CITY_LIST':
      return {
        ...state,
        cityListData: action.cityListData
      }
    case 'GET_BRANCH_ENQUIRY_LIST':
      return {
        ...state,
        branchListData: action?.branchListData
      }
    case 'GET_REPORT_DATA':
      return {
        ...state,
        leadReportData: action.leadReportData
      }
    case 'GET_SELL_PERSON_LIST':
      return {
        ...state,
        sellPersonData: action.sellPersonData
      }
    case 'GET_MULTI_QUOTATION_REPORT_DATA':
      return {
        ...state,
        multiQuotationData: action.multiQuotationData
      }

    default:
      return { ...state }
  }
}
export default leadReport
