const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  cityListData: [],
  leadReportData: [],
  sellPersonData: [],


}

const quotationReportStore = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_QUOTATION_REPORT':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        // statusCounts: action.statusCounts
      }
    case 'GET_CITY_LIST':
      return {
        ...state,
        cityListData: action.cityListData
      }
    case 'GET_BRANCH_ENQUIRY_LIST':
      return {
        ...state,
        branchListData: action?.branchListData
      }
    case 'GET_REPORT_DATA':
      return {
        ...state,
        leadReportData: action.leadReportData
      }
    case 'GET_SELL_PERSON_LIST':
      return {
        ...state,
        sellPersonData: action.sellPersonData
      }
    default:
      return { ...state }
  }
}
export default quotationReportStore
